header {
  width: 100%;
  position: absolute;
  top: 0;
}

body {
  margin: 0;
  font-family: -apple-system, Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.TitleStuff{
color: white;
text-align: center;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
font-size: 48px;
}

/* navbar.scss */
.navbar{
  font-size: 1.525rem;
  width: 100%;
  position: fixed;
  background-color: rgb(23, 39, 61, 80%);
}
.navSpaceMobile{
  width: 0px;
  height: 0px
}
.mobile-nav {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
}

.navSpace{
  height: 72px
}
.bottomNavSpace{
  height: 32px
}
.centerContent{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  transform: translate(-50%,-50%);
}

.subTitleStuff{
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paraSize{
  height: 700px;

}

Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}
.emails{
  justify-content: center;
  width: auto;
  margin: 10%;
  text-align: left;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 500px) {
  .TitleStuff{
    font-size: 32px;
    }
    .paraSize{
      height: 500px;
    }
  .navSpaceMobile {
    width: 100%;
    height: 64px
  }
  .bottomNavSpace{
    height: 0px
  }
.emails{
  justify-content: center;
  width: auto;
  margin: auto;
  text-align: left;
}

}